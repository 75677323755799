import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SystemConfigurationService {
  constructor(private http: HttpClient) {}

  getScreens(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/screens?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getScreensV2(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/screens/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addNewScreen(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/screens?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDetail(organization_form): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/public_medical_unit/`,
        {
          organization_form,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getModulesList(): Observable<any> {
    const results = this.http
      .get(`${environment.pythonUrl}/api/roles/menus_against_sys_admin_role/`, {
        headers: new HttpHeaders({
          // AuthenticationToken: localStorage.getItem('auth_token'),
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
    return results;
  }
  submitFile(file, type): Observable<any> {
    let url = '';
    if (type === 'price_list') {
      url = 'create_pricing_script';
    } else if (type === 'misc_service') {
      url = 'create_misc_service_script';
    } else if (type === 'radiology_service') {
      url = 'create_radiology_service_script';
    } else if (type === 'ot_procedure') {
      url = 'create_ot_procedure_service_script';
    } else if (type === 'product') {
      url = 'create_product_script';
    } else if (type === 'vendor') {
      url = 'create_vendor_script';
    } else if (type === 'update_stock') {
      url = 'stock_upload';
    } else if (type === 'upload_medicine') {
      url = 'create_items_script';
    } else if (type === 'upload_patient_list') {
      url = 'create_patient_script';
    } else {
      url = 'create_employee_script';
    }
    const body = new FormData();
    body.append('file', file);
    const results = this.http.post(
      `${environment.pythonUrl}/script/` +
        url +
        `?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  submitDataFile(file, type): Observable<any> {
    let url = '';
    if (type === 'product_data') {
      url = 'update_product_data';
    } else if (type === 'misc_data') {
      url = 'update_misc_service_script';
    } else if (type === 'radiology_data') {
      url = 'update_radiology_service_script';
    }
    const body = new FormData();
    body.append('file', file);
    const results = this.http.post(
      `${environment.pythonUrl}/script/` +
      url +
      `?auth_token=` +
      localStorage.getItem('auth_token') +
      '&medical_unit_id=' +
      localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  addNewScreenV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/screens/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatedNewScreen(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/screens/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatedNewScreenV2(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_screen/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getMenus(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMenusV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewMenu(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewMenuV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatedNewMenu(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatedNewMenuV2(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_menu/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSubMenus(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/sub_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSubMenusV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/sub_menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewSubMenus(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/sub_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewSubMenusV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/sub_menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatedNewSubMenus(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/sub_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatedNewSubMenusV2(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_sub_menus/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSubMenuScreens(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/role_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addSubMenuScreens(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/role_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateSubMenuScreens(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        environment.rorUrl +
          '/v4/role_menus/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getMenusMenuDetail(id): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/role_menus/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // role menus
  getRoleMenus(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/role_menus?role_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRoleMenusV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/role_menus/?role_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleMenu(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/role_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleMenuV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/role_menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleMenu(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/role_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleMenuV2(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_role_menus/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleMenu(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.rorUrl}/v4/role_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleMenuV2(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.pythonUrl}/api/role_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // role sub menus
  getRoleSubMenus(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/role_menu_sub_menus?role_menu_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRoleSubMenusV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/role_menu_sub_menus/?role_menu_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addRoleSubMenu(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/role_menu_sub_menus?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleSubMenuV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/role_menu_sub_menus/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateRoleSubMenu(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/role_menu_sub_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleSubMenuV2(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_role_menu_sub_menus/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleSubMenu(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.rorUrl}/v4/role_menu_sub_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleSubMenuV2(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.pythonUrl}/api/role_menu_sub_menus/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // role sub menu screens
  getRoleSubMenuScreens(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/role_menu_sub_menu_screens?role_menu_sub_menu_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRoleSubMenuScreensV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/role_menu_sub_menu_screens/?role_menu_sub_menu_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRoleSubMenuScreensWithControlsV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/list_new_update_role_menu_sub_menu_screens/?role_menu_sub_menu_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitScreensWithControls(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/list_new_update_role_menu_sub_menu_screens/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu_screens: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleSubMenuScreen(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/role_menu_sub_menu_screens?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu_screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addRoleSubMenuScreenV2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/role_menu_sub_menu_screens/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu_screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleSubMenuScreen(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/role_menu_sub_menu_screens/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu_screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoleSubMenuScreenV2(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/role_menu_sub_menu_screens/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          role_menu_sub_menu_screen: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleSubMenuScreen(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.rorUrl}/v4/role_menu_sub_menu_screens/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  removeRoleSubMenuScreenV2(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.pythonUrl}/api/role_menu_sub_menu_screens/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createInvoiceChecks(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/medical_units/update_report_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          report_controls: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createInvoiceChecksv2(obj: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_report_controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          report_controls: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addNewScreenRole(
    code,
    name,
    is_default,
    is_active,
    screen_id,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          screen_id,
          is_default,
          is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewScreenRoleV2(
    code,
    name,
    is_default,
    is_active,
    screen_id,
    copy_to_all_med_units,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          screen_id,
          is_default,
          is_active,
          copy_to_all_med_units,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateScreenRole(code, name, is_default, is_active, id): Observable<any> {
    const results = this.http
      .put(
        environment.rorUrl +
          '/v4/controls/' +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          is_default,
          is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateScreenRoleV2(code, name, is_default, is_active, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/api/controls/' +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          is_default,
          is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getScreenControl(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/controls?screen_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getScreenControlV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/controls/?screen_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getScreenControlByRole(submenu, menu): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/screen_controls?sub_menu_screen_id=' +
          submenu +
          '&screen_id=' +
          menu +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getScreenControlByRoleV2(submenu, menu): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/screen_controls/?sub_menu_screen_id=' +
          submenu +
          '&screen_id=' +
          menu +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deleteScreenControl(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.rorUrl}/v4/controls/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  deleteScreenControlV2(id): Observable<any> {
    const results = this.http
      .delete(
        `${environment.pythonUrl}/api/controls/` +
        id +
        '?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTokenExpFunChecks(): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/medical_units/get_auth_check?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTokenExpFunChecksv2(): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/get_auth_check/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTokenExpFunChecks(data): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/medical_units/update_auth_check?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token_check: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateTokenExpFunChecksv2(data): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_auth_check/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token_check: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalUnitList(): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v1/medical_units?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cloneMedicalUnitData(values): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/medical_units/copy_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_from: values.medical_unit_from,
          medical_unit_to: values.medical_unit_to,
          type: values.type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // getMedicalUnit(): Observable<any> {
  //   const results = this.http.get(environment.rorUrl +  '/v4/medical_units/?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id')
  //     , {headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  getMedicalUnitV2(): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/all_medical_unit/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // medicalUnits Images api Start
  addImageMedicalUnit(file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    const results = this.http.post(
      `${environment.pythonUrl}/camden_website/upload_hospital_image/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  // medicalUnits Images api end

  updateMedicalUnit(obj: any, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/medical_units/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateMedicalUnitV2(
    obj: any,
    id,
    front_desk,
    ris,
    lis,
    pharmacy,
    ot,
    dialysis,
    opd,
    ipd,
    blood_bank,
    emergency,
    donation,
    hr,
    pay_roll,
    roastr,
    inventory,
    finance,
    complaint_manage,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/add_medical_unit/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit: obj,
          front_desk,
          ris,
          lis,
          pharmacy,
          ot,
          dialysis,
          opd,
          ipd,
          blood_bank,
          emergency,
          donation,
          hr,
          pay_roll,
          roastr,
          inventory,
          finance,
          complaint_manage,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addNewMedicalUnitV2(
    value,
    front_desk,
    ris,
    lis,
    pharmacy,
    ot,
    dialysis,
    opd,
    ipd,
    blood_bank,
    emergency,
    donation,
    hr,
    pay_roll,
    roastr,
    inventory,
    finance,
    complaint_manage,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/add_medical_unit/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit: value,
          front_desk,
          ris,
          lis,
          pharmacy,
          ot,
          dialysis,
          opd,
          ipd,
          blood_bank,
          emergency,
          donation,
          hr,
          pay_roll,
          roastr,
          inventory,
          finance,
          complaint_manage,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addNewMedicalUnit(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/medical_units/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addNewFeature(code, name, is_active, screen_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/screen_features/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          screen_id,
          is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getScreenFeatures(id, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/screen_features/?screen_id=` +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateScreenFeature(code, name, is_active, screen_id, id): Observable<any> {
    const results = this.http
      .put(
        environment.pythonUrl +
          '/api/screen_features/' +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          code,
          name,
          screen_id,
          is_active,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
