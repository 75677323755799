<!--begin::Search-->
<div class="location_name">
  <div class="quick-search quick-search-dropdown p-0 quick-search-has-result">
    <!--begin:Form-->
    <form method="get" class="quick-search-form">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text px-2 py-0">
            <span class="svg-icon svg-icon-primary svg-icon-lg">
              <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </span>
        </div>
        <input
          type="text"
          autofocus
          class="form-control form-control-sm"
          id="productsSearch"
          name="searchProductValue"
          [(ngModel)]="selectedProduct"
          #search_string
          (input)="getLoadItems(search_string.value)"
          placeholder="Search..."
          autocomplete="off"
        />
        <div class="input-group-append">
          <span class="input-group-text px-2 py-0">
            <i
              class="quick-search-close ki ki-close icon-sm text-muted"
              (click)="removeSearchString()"
            ></i>
          </span>
        </div>
      </div>
    </form>
    <div
      class="location-search-bar pointOfSale"
      *ngIf="selectedProduct && showloadItemsData"
    >
      <table class="table table-head-custom tableBorder">
        <thead>
          <tr>
            <th *ngIf="type !== 'services' && type !== 'donor'" style="width: 30%; text-align: center">Code</th>
            <th style="width: 70%; text-align: center">{{ type === 'donor' ? 'Donor' : type === 'services' ? 'Service Name' : 'Product Name' }}</th>
          </tr>
        </thead>
        <tbody class="pointOFSale2" id="innerScroll">
          <tr
            class="hoverPro d-flex"
            *ngFor="let products of products; let i = index"
            [ngClass]="{
              hoverProduct: activeIndex === i
            }"
            id="productID{{ i }}"
            (click)="appendRow(products)"
          >
            <td *ngIf="type !== 'services' && type !== 'donor'" style="width: 30%; text-align: center">
              {{ products?.code }}
            </td>
            <td style="text-align: left" [style.width]="type === 'services' ? '100%' : type === 'donor' ? '100%' : '70%'">
              {{ type === 'donor' ? products.first_name + ' ' + products.last_name + ' ' +  '(' + products?.mrn + ')' : products?.name}}
            </td>
<!--            <td *ngIf="type === 'donor'" style="width: 100%; text-align: left">-->
<!--              {{ products.first_name }} {{ products.last_name }} ({{products?.mrn}})-->
<!--            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Scroll-->
  </div>
</div>
<!--end::Search-->
<ng-container *ngIf="type !== 'services' || type !== 'donor' || type !== 'movementReport'">
<ng-container *ngFor="let data of productSearchArray; let i = index">
  <div class="d-flex justify-content-between">
    <div *ngIf="i <= 2">
      <small>{{ data?.name }}</small>
    </div>
    <div class="input-group-append" *ngIf="i <= 2">
      <span>
        <i
          class="ki ki-close text-muted; cursor-pointer"
          style="font-size: 0.5rem"
          (click)="deleteProduct(i)"
        ></i>
      </span>
    </div>
  </div>
</ng-container>
<div>
  <small>
    <a
      *ngIf="productSearchArray.length > 3"
      class="showMore"
      (click)="showAllProduct()"
      >Show more</a
    >
  </small>
</div>
</ng-container>
<!--show All Product Modal Start-->
<div id="allProductsModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Products</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="" aria-expanded="true" *ngIf="productSearchArray.length">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th style="width: 20%" class="text-muted font-weight-bold">
                  Code
                </th>
                <th style="width: 25%" class="text-muted font-weight-bold">
                  Name
                </th>
                <th
                  style="width: 22%; text-align: center"
                  class="text-muted font-weight-bold"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of productSearchArray; let i = index">
                <td class="font-weight-bold">
                  {{ data?.code }}
                </td>
                <td>
                  <span class="d-block font-weight-bold">{{ data?.name }}</span>
                </td>
                <td style="text-align: center">
                  <i
                    class="ki ki-close text-muted cursor-pointer"
                    style="font-size: 0.8rem"
                    (click)="deleteProduct(i)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-sm btn-default"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--show All Product Modal End-->
