import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PatientService} from '../../../services/patient.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ServiceService} from '../../../services/service.service';
import {HospitaldoctorService} from '../../../services/hospitaldoctor.service';
import {SystemControlsService} from '../../../services/system-controls.service';
import {WardService} from '../../../services/ward.service';
import {PatientAdmissionService} from '../../../services/patient-admission.service';
import {DepartmentsbyhospitalService} from '../../../services/departmentsbyhospital.service';
import {LookupService} from '../../../services/lookup.service';
import {OpdService} from '../../../services/opd.service';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute} from '@angular/router';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-patient-admission-modal',
  templateUrl: './patient-admission-modal.component.html',
  styleUrls: ['./patient-admission-modal.component.css']
})
export class PatientAdmissionModalComponent implements OnInit {
  @Input() patient: any;
  @Input() doctors_by_dept: any;
  @Input() lookupsRelationList: any;
  @Input() department: any;
  @Output() closeModalAdmission: EventEmitter<boolean> = new EventEmitter();
  PatientAdmissionForm: UntypedFormGroup;
  submitted = false;
  isSubmitAdmission = false;
  isDisableSubmit = false;
  doctor = [];
  doctors = [];
  doctorsAll = [];
  wards = [];
  beds = [];
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: UntypedFormBuilder,
    private hospitalDoctorsService: HospitaldoctorService,
    private wardService: WardService,
    private patientAdmissionService: PatientAdmissionService) { }

  ngOnInit(): void {
    this.PatientAdmissionForm = this.formBuilder.group({
      visit_id: [''],
      patient_id: [''],
      referred_from: ['', Validators.required],
      referred_to: [''],
      ward_id: ['', Validators.required],
      bed_id: [''],
      doctor_id: ['', Validators.required],
      user_id: [''],
      attendent_name: [''],
      attendent_mobile: [''],
      attendent_address: [''],
      attendent_nic: [''],
      attendent_relationship: [''],
      admission_number: [''],
      status: [''],
      condition: ['normal'],
      comments: [''],
      medical_officer_id: [''],
      admission_orders: [''],
    });
    setTimeout(() => {
      $('#doctorId').selectpicker('refresh');
      $('#wardId').selectpicker('refresh');
      $('#referredFrom').selectpicker('refresh');
      $('#bedId').selectpicker('refresh');
    }, 500);
    setTimeout(() => {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        this.PatientAdmissionForm.patchValue({
          doctor_id: currentUser.id,
          referred_from: currentUser.doctor_department_id,
        });
      }
      $('#doctorId').selectpicker('refresh');
      $('#referredFrom').selectpicker('refresh');
      $('#admission_orders').summernote({
        height: 100,
        // placeholder: 'History of Hospitalisation',
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          // ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 200);
    this.fetchWards();
  }
  get admissionCont(): any {
    return this.PatientAdmissionForm.controls;
  }
  getDepartByDoc(id): any {
    if (id) {
      const value = this.doctors_by_dept.filter((t) => t.id === Number(id))[0]
        .doctor_department_id;
      this.PatientAdmissionForm.get('referred_from').patchValue(value);
    }
    setTimeout(() => {
      $('#referredFrom').selectpicker('refresh');
    }, 100);
  }

  fetchDoctorsByDep(index): any {
    this.doctors_by_dept = [];
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorList(index).subscribe(
      (data) => {
        this.doctors_by_dept = data.users;
        setTimeout(() => {
          $('#doctorId').selectpicker('refresh');
        }, 200);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#doctorId').selectpicker();
        }, 500);
        setTimeout(() => {
          $('#doctorId').selectpicker('refresh');
        }, 500);
        toastr.error(err.error.message);
      },
    );
  }
  fetchWards(): any {
    this.ngxLoader.start();
    this.wardService.activeWardNamesV2().subscribe(
      (data) => {
        this.wards = data.wards;
        setTimeout(() => {
          $('#wardId').selectpicker('refresh');
          $('#wardId').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  updateWard(bed, id): any {
    this.PatientAdmissionForm.controls.bed_id.setValue('');
    const obj = this.wards.find((item) => item.id === parseInt(id));
    this.beds = obj.beds;
    setTimeout(() => {
      $('#bedId').selectpicker();
    }, 500);
    setTimeout(() => {
      $('#bedId').selectpicker('refresh');
    }, 500);
    // console.log(this.beds);
    if (bed) {
      this.PatientAdmissionForm.get('bed_id').patchValue(bed.bed_id);
    }
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  createAdmission(): any {
    this.PatientAdmissionForm.get('ward_id').setValidators([
      Validators.required,
    ]);
    this.PatientAdmissionForm.get('ward_id').updateValueAndValidity();

    if (this.patient.admitted === true) {
      toastr.error('You cannot admit a patient who is already admitted.');
      return;
    }
    this.isSubmitAdmission = true;
    if ($('#admission_orders').summernote('isEmpty') === false) {
      this.PatientAdmissionForm
        .get('admission_orders')
        .setValue(
          $('#admission_orders').summernote('code')
        );
    } else {
      this.PatientAdmissionForm.get('admission_orders').setValue('');
    }
    this.PatientAdmissionForm.value.patient_id = this.patient.id;
    this.PatientAdmissionForm.value.visit_id = this.patient?.visit_id;
    this.PatientAdmissionForm.value.status = 'Admitted';
    if (this.PatientAdmissionForm.invalid || !this.patient.id) {
      return;
    }
    this.isDisableSubmit = true;
    this.ngxLoader.start();

    this.patientAdmissionService
      .createV2(this.PatientAdmissionForm.value, '')
      .subscribe(
        (data) => {
          // localStorage.removeItem('patientAdmission');
          // localStorage.removeItem('patientSearch');
          this.isDisableSubmit = false;
          this.ngxLoader.stop();
          toastr.success('Successfully Admitted');
          this.closeAdmissionModal();
          // window.open(this.current_environment+"/v3/patients/"+data.admission.patient.id+"/admission_slip.pdf?auth_token="+localStorage.getItem('auth_token')+'&medical_unit_id='+localStorage.getItem('current_medical_unit_id'));
          // this.patientRegistration(this.patient);
          // this.fetchWards();
        },
        (err) => {
          this.ngxLoader.stop();
          this.isDisableSubmit = false;
          toastr.error(err.error.message);
        },
      );
  }

  createAdmissionRequest(): any {
    this.PatientAdmissionForm.get('ward_id').setValidators([]);
    this.PatientAdmissionForm.get('ward_id').updateValueAndValidity();

    if (this.patient.admitted === true) {
      toastr.error('You cannot admit a patient who is already admitted.');
      return;
    }
    this.isSubmitAdmission = true;
    if ($('#admission_orders').summernote('isEmpty') === false) {
      this.PatientAdmissionForm
        .get('admission_orders')
        .setValue(
          $('#admission_orders').summernote('code')
        );
    } else {
      this.PatientAdmissionForm.get('admission_orders').setValue('');
    }
    this.PatientAdmissionForm.value.patient_id = this.patient.id;
    this.PatientAdmissionForm.value.visit_id = this.patient?.visit_id;
    this.PatientAdmissionForm.value.status = 'requested';
    if (this.PatientAdmissionForm.invalid || !this.patient.id) {
      return;
    }
    this.isDisableSubmit = true;
    this.ngxLoader.start();

    this.patientAdmissionService
      .createAdmissionRequestV2(this.PatientAdmissionForm.value)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Admission Request Created');
          this.isDisableSubmit = false;
          this.closeAdmissionModal();
        },
        (err) => {
          this.ngxLoader.stop();
          this.isDisableSubmit = false;
          toastr.error(err.error.message);
        },
      );
  }
  closeAdmissionModal(): any{
    this.closeModalAdmission.emit(true);
    this.PatientAdmissionForm.reset({
      visit_id: '',
      patient_id: '',
      referred_from: '',
      referred_to: '',
      // department_id: ['', Validators.required],
      ward_id: '',
      bed_id: '',
      doctor_id: '',
      user_id: '',
      attendent_name: '',
      attendent_mobile: '',
      attendent_address: '',
      attendent_nic: '',
      attendent_relationship: '',
      admission_number: '',
      status: '',
      condition: 'normal',
      comments: '',
      medical_officer_id: '',
    });
    this.beds = [];
    this.doctors_by_dept = [];
    this.doctors_by_dept = this.doctor;
  }
}
